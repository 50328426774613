import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { Cleaner } from './cleaner';
import { Coating } from './coating';
import { MTLLoader } from '../obj/MTLLoader';
import { WebglScene } from '../scene';

declare let AssetManagerServices;

export class Material {

    static default(color: THREE.Color, env: THREE.Texture, options: any) : THREE.MeshPhongMaterial {
        var result = new THREE.MeshPhongMaterial({
            color: color,
            dithering: true,
            reflectivity: (options && options.reflectivity != undefined ? options.reflectivity : 0),
            transparent: (options && options.transparent != undefined ? options.transparent : false),
            envMap: env != undefined ? env : null,
            side: (options && options.side != undefined ? options.side : THREE.FrontSide),
            name: (options && options.name != undefined ? options.name : undefined)
        });
        return result;
    }

    static cloneMap(source: THREE.Texture, target: THREE.Texture, scene: WebglScene) {
        const sourceMap = scene.renderer.properties.get(source);
        if (!sourceMap.__webglTexture) scene.renderer.initTexture(source);
        const targetMap = scene.renderer.properties.get(target);
        Object.assign(targetMap, sourceMap);
        if (!(source as any).sharedRef) {
            (source as any).sharedRef = 0;
        }
        (source as any).sharedRef++;
        (target as any).weakRef = source;
    }

    static clone(material: THREE.MeshPhongMaterial, scene: WebglScene) : THREE.MeshPhongMaterial {
        var result = material.clone();
        (result as any).alwaysTransparent = (material as any).alwaysTransparent;
        if (material.map) {
            result.map = material.map.clone();
            Material.cloneMap(material.map, result.map, scene);
        }
        if (material.normalMap) {
            result.normalMap = material.normalMap.clone();
            Material.cloneMap(material.normalMap, result.normalMap, scene);
        }
        if (material.bumpMap) {
            result.bumpMap = material.bumpMap.clone();
            Material.cloneMap(material.bumpMap, result.bumpMap, scene);
        }
        if (material.alphaMap) {
            result.alphaMap = material.alphaMap.clone();
            Material.cloneMap(material.alphaMap, result.alphaMap, scene);
        }
        if (material.emissiveMap) {
            result.emissiveMap = material.emissiveMap.clone();
            Material.cloneMap(material.emissiveMap, result.emissiveMap, scene);
        }
        return result;
    }

    static applyMaterialFromCoating(basePath: string, mesh: THREE.Mesh, coating: SavaneJS.Coating, materials: Array<THREE.MeshPhongMaterial>, scene: WebglScene, loaded: CallableFunction) : void {
        for (var i = 0; i < materials.length; ++i) {
            var cloned = Material.clone(materials[i], scene);
            (cloned as any).basePath = basePath;
            if (cloned.map) {
                // Yes so assign set the rotation and assign the material
                if (coating.floorGeneratorSettings === undefined) {
                    if (coating.rotation) {
                        cloned.map.rotation = (coating.rotation * Math.PI) / 180;
                    }
                    else {
                        cloned.map.rotation = 0;
                    }
                }

                if (coating.offset) {
                    cloned.map.offset.x = (coating.offset[0]);
                    cloned.map.offset.y = (coating.offset[1]);
                }
                else {
                    cloned.map.offset.x = 0;
                    cloned.map.offset.y = 0;
                }
                if ((cloned.map as any).originalRepeat === undefined) {
                    (cloned.map as any).originalRepeat = new THREE.Vector2(cloned.map.repeat.x, cloned.map.repeat.y);
                }
                if (coating.repeat) {
                    cloned.map.repeat.x = (cloned.map as any).originalRepeat.x * (coating.repeat[0]);
                    cloned.map.repeat.y = (cloned.map as any).originalRepeat.y * (coating.repeat[1]);
                }
                else {
                    cloned.map.repeat.x = (cloned.map as any).originalRepeat.x * 1;
                    cloned.map.repeat.y = (cloned.map as any).originalRepeat.y * 1;
                }
            }

            if (Array.isArray(mesh.material)) {
                for (var j = 0; j < mesh.material.length; ++j) {
                    Material.applyMaterial(mesh, cloned, coating, j);
                }
            } else {
                Material.applyMaterial(mesh, cloned, coating, undefined);
            }
        }

        scene.updateEnvs();
        if (!scene.settings.interactiveProject || scene.loaded) {
            scene.render();
        }

        if (loaded) {
            loaded();
        }
    }

    static mapsNumber(materials: Array<THREE.MeshPhongMaterial>) : number {
        var result = 0;
        for (var i = 0; i < materials.length; ++i) {
            var material = materials[i];
            if (material.map) {
                result++;
            }
            if (material.normalMap) {
                result++;
            }
            if (material.bumpMap) {
                result++;
            }
            if (material.alphaMap) {
                result++;
            }
            if (material.emissiveMap) {
                result++;
            }
        }

        return result;
    }

    static loadMtl(basePath: string, mesh: THREE.Mesh, coating: SavaneJS.Coating, scene: WebglScene, loaded: CallableFunction) : void {
        var mtlLoader = new MTLLoader();
        mtlLoader.crossOrigin = 'anonymous';
        // Set MTL and JPG path to load textures
        mtlLoader.setPath(basePath);
        mtlLoader.setMaterialOptions({ wrap: THREE.RepeatWrapping });
        mtlLoader.load("object.mtl", function(loader) {
            // Preload textures
            loader.preload();

            // Get material list from loader
            var listMaterials = Object.keys(loader.materials).map(function(e) {
                return loader.materials[e];
            });

            if (listMaterials.length > 0) {
                Material.applyMaterialFromCoating(basePath, mesh, coating, listMaterials, scene, loaded);
            }
        }, null, function() {
            if (loaded) {
                loaded();
            }
        });
    }

    static applyMaterial(mesh: THREE.Mesh, material: THREE.MeshPhongMaterial, coating: SavaneJS.Coating, index: number) {
        var isJoinery = mesh.name.indexOf("Joinery") !== -1;
        var isStair = mesh.name.indexOf("Stair") !== -1;
        var isTechnicalElementPlinth = mesh.name.indexOf("TechnicalElementPlinth") !== -1;
        var isTechnicalElement = mesh.name.indexOf("TechnicalElement") !== -1 && !isTechnicalElementPlinth;

        if (Array.isArray(mesh.material)) {
            var name = mesh.material[index].name;
            var envMap = (mesh.material[index] as THREE.MeshPhongMaterial).envMap;
            if (isJoinery) {
                if (name === "1" || name === "10") {
                    Cleaner.cleanMaterial(mesh.material[index]);
                    mesh.material[index] = material;
                }
            } else {
                if (isStair || isTechnicalElement) {
                    if ((coating.hangType === SavaneJS.Coating.HangType.usemtl) && (name === coating.usemtlName)) {
                        Cleaner.cleanMaterial(mesh.material[index]);
                        mesh.material[index] = material;
                    }
                }
                else {
                    Cleaner.cleanMaterial(mesh.material[index]);
                    mesh.material[index] = material;
                }
            }
            mesh.material[index].name = name;
            (mesh.material[index] as THREE.MeshPhongMaterial).envMap = envMap;
            mesh.material[index].side = THREE.FrontSide;
            mesh.material[index].needsUpdate = true;
        }
        else {
            var name = mesh.material.name;
            var envMap = (mesh.material as THREE.MeshPhongMaterial).envMap;
            if (isStair || isTechnicalElement) {
                if (coating.hangType === undefined || coating.hangType === SavaneJS.Coating.HangType.technicalElement || (coating.hangType === SavaneJS.Coating.HangType.usemtl) && (name === coating.usemtlName)) {
                    Cleaner.cleanMaterial(mesh.material);
                    mesh.material = material;
                }
            } else if (isJoinery && coating.hangType === SavaneJS.Coating.HangType.joinery && (name === "1" || name === "10")) {
                Cleaner.cleanMaterial(mesh.material);
                mesh.material = material;
            }
            else if (!isJoinery) {
                Cleaner.cleanMaterial(mesh.material);
                mesh.material = material;
            }
            mesh.material.name = name;
            (mesh.material as THREE.MeshPhongMaterial).envMap = envMap;
            mesh.material.side = THREE.FrontSide;
            mesh.material.needsUpdate = true;
        }
    }

    // Loads a material to apply to a mesh. Parameters are the mesh on which to apply the material, the coating to apply (can be of 2 types, coatingAsset and coatingComponent), and if the coating is a component (true) or a coating asset (false)
    static setMaterialFromCoating(mesh: THREE.Mesh, coating: SavaneJS.Coating | { coatingId: string }, component: boolean, config: number, scene: WebglScene, render: boolean, loaded: CallableFunction) : void {
        if (render === undefined) {
            render = true;
        }
        // No so default color assignment
        var defaultColor;
        // If the coating is a component coating
        if (component) {
            defaultColor = Coating.getColorFromComponent(coating as SavaneJS.Coating, 0xaaaaaa);
        }
        else {
            defaultColor = Coating.getColorFromAsset(coating as SavaneJS.Coating, 0xaaaaaa);
        }

        var envMap = (mesh.material as THREE.MeshPhongMaterial).envMap;
        var newMaterial = Material.default(new THREE.Color(defaultColor).convertSRGBToLinear(), envMap, { side: THREE.FrontSide });

        if (Array.isArray(mesh.material)) {
            for (var i = 0; i < mesh.material.length; ++i) {
                Material.applyMaterial(mesh, newMaterial, coating as SavaneJS.Coating, i);
            }
        } else {
            Material.applyMaterial(mesh, newMaterial, coating as SavaneJS.Coating, undefined);
        }

        if (render) {
            scene.updateEnvs();
            if (!scene.settings.interactiveProject) {
                scene.render();
            }
        }

        // Get asset manager base url
        var basePath;

        if (component) {
            basePath = AssetManagerServices.getUrl() + 'api/v1/coatings/' + (coating as SavaneJS.Coating).coatingId + '/medias/vlp' + (config !== undefined && config !== null ? "_" + config + "/" : "/");
        }
        else {
            basePath = AssetManagerServices.getUrl() + 'api/v1/coatings/' + (coating as SavaneJS.Coating).coatingId + '/medias/vlp' + (config !== undefined && config !== null ? "_" + config + "/" : "/");
        }

        Material.loadMtl(basePath, mesh, coating as SavaneJS.Coating, scene, loaded);
    }

    static loadTechnicalMtl(basePath: string, mesh: THREE.Mesh, scene: WebglScene, render: boolean, loaded: CallableFunction) : void {
        // Create a MTL loade to load textures
        var mtlLoader = new MTLLoader();
        mtlLoader.crossOrigin = 'anonymous';
        // Set MTL and JPG path to load textures
        mtlLoader.setPath(basePath);
        mtlLoader.setMaterialOptions({ wrap: THREE.RepeatWrapping });

        // Now load the MTL and potential textures in it
        mtlLoader.load("object.mtl", function(loader) {
            // Preload textures
            loader.textureLoaded = function() {
                scene.updateEnvs();
                if (!scene.settings.interactiveProject || scene.loaded) {
                    scene.render();
                }
                if (loaded) {
                    loaded();
                }
            };
            loader.preload();

            // Get material list from loader
            var listMaterials = Object.keys(loader.materials).map(function(e) {
                return loader.materials[e];
            });

            // Any material found ?
            let callCallback = true;

            if (listMaterials.length > 0) {
                if (Array.isArray(mesh.material)) {
                    for (var k = 0; k < mesh.material.length; ++k) {
                        for (var j = 0; j < listMaterials.length; j++) {
                            // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                            if (listMaterials[j].name === mesh.material[k].name) {
                                var envMap = (mesh.material[k] as THREE.MeshPhongMaterial).envMap;
                                Cleaner.cleanMaterial(mesh.material[k]);
                                mesh.material[k] = listMaterials[j];
                                (mesh.material[k] as THREE.MeshPhongMaterial).envMap = envMap;
                                mesh.material[k].side = THREE.FrontSide;

                                if (listMaterials[j].map) {
                                    callCallback = false;
                                }
                                break;
                            }
                        }
                    }
                } else {
                    // Parse materials loaded by MTL loader
                    for (var j = 0; j < listMaterials.length; j++) {
                        // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                        if (listMaterials[j].name === mesh.material.name) {
                            var envMap = (mesh.material as THREE.MeshPhongMaterial).envMap;
                            Cleaner.cleanMaterial(mesh.material);
                            mesh.material = listMaterials[j];
                            (mesh.material as THREE.MeshPhongMaterial).side = THREE.FrontSide;
                            (mesh.material as THREE.MeshPhongMaterial).envMap = envMap;

                            if (listMaterials[j].map) {
                                callCallback = false;
                            }
                            break;
                        }
                    }
                }
            }
            else {
                (mesh.material as THREE.MeshPhongMaterial).color = new THREE.Color(0xffffff);
                (mesh.material as THREE.MeshPhongMaterial).side = THREE.FrontSide;
            }

            if (callCallback) {
                loaded();
            }

            if (render) {
                scene.updateEnvs();
                if (!scene.settings.interactiveProject) {
                    scene.render();
                }
            }
        });
    }

    // Loads a material to apply to a mesh. Parameters are the mesh on which to apply the material, the technical element entity from which we will retieve the .mtl from the asset manager
    static setMaterialFromTechnicalElement(mesh: THREE.Mesh, techElement: SavaneJS.TechnicalElement, scene: WebglScene, render: boolean, loaded: CallableFunction) : void {
        if (render === undefined) {
            render = true;
        }
        var components = techElement.getComponents(SavaneJS.ComponentConstants.ComponentType.TechnicalElementType) as Array<SavaneJS.TechnicalElementType>;
        if (components.length > 0) {
            // Retrieve asset id
            var id = components[0].technicalElementTypeId;
            // Get asset manager base url
            var basePath = AssetManagerServices.getUrl() + 'api/v1/technicalelements/' + id + '/medias/vlp/';
            Material.loadTechnicalMtl(basePath, mesh, scene, render, loaded);
        }
    }

    static setMaterialFromDecoratedWallsElement(mesh: THREE.Mesh, techElement: SavaneJS.TechnicalElement, scene: WebglScene, render: boolean, loaded: CallableFunction) : void {
        if (render === undefined) {
            render = true;
        }
        var components = techElement.getComponents(SavaneJS.ComponentConstants.ComponentType.WallType) as Array<SavaneJS.WallType>;
        if (components.length > 0) {
            // Retrieve asset id
            var id = components[0].wallTypeId;
            // Get asset manager base url
            var basePath = AssetManagerServices.getUrl() + 'api/v1/decoratedWalls/' + id + '/medias/vlp/';
            Material.loadTechnicalMtl(basePath, mesh, scene, render, loaded);
        }
    }

    static setMultiMaterial(mesh: THREE.Mesh, id: string, config: number, scene: WebglScene, render: boolean, done: CallableFunction) : void {
        if (render === undefined) {
            render = true;
        }

        // Get asset manager base url
        var basePath = AssetManagerServices.getUrl() + 'api/v1/coatings/' + id + '/medias/vlp' + (config !== undefined && config !== null ? "_" + config + "/" : "/");
        // Create a MTL loade to load textures
        var mtlLoader = new MTLLoader();
        mtlLoader.crossOrigin = 'anonymous';
        // Set MTL and JPG path to load textures
        mtlLoader.setPath(basePath);
        mtlLoader.setMaterialOptions({ wrap: THREE.RepeatWrapping });

        // Now load the MTL and potential textures in it
        mtlLoader.load("object.mtl", function(loader) {
            // Preload textures
            loader.textureLoaded = function() {
                scene.updateEnvs();
                if (!scene.settings.interactiveProject || scene.loaded) {
                    scene.render();
                }
                if (done) {
                    done();
                }
            };
            loader.preload();

            // Get material list from loader
            var listMaterials = Object.keys(loader.materials).map(function(e) {
                return loader.materials[e];
            });

            // Any material found ?
            if (listMaterials.length > 0) {
                if (Array.isArray(mesh.material)) {
                    for (var k = 0; k < mesh.material.length; ++k) {
                        for (var j = 0; j < listMaterials.length; j++) {
                            // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                            if (listMaterials[j].name === mesh.material[k].name) {
                                var envMap = (mesh.material[k] as THREE.MeshPhongMaterial).envMap;
                                Cleaner.cleanMaterial(mesh.material[k]);
                                mesh.material[k] = listMaterials[j];
                                (mesh.material[k] as THREE.MeshPhongMaterial).envMap = envMap;
                                mesh.material[k].side = THREE.FrontSide;
                                break;
                            }
                        }
                    }
                } else {
                    // Parse materials loaded by MTL loader
                    for (var j = 0; j < listMaterials.length; j++) {
                        // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                        if (listMaterials.length === 1 || listMaterials[j].name === mesh.material.name) {
                            var envMap = (mesh.material as THREE.MeshPhongMaterial).envMap;
                            Cleaner.cleanMaterial(mesh.material);
                            mesh.material = listMaterials[j];
                            (mesh.material as THREE.MeshPhongMaterial).envMap = envMap;
                            (mesh.material as THREE.MeshPhongMaterial).side = THREE.FrontSide;
                            break;
                        }
                    }
                }
            }
            else {
                (mesh.material as THREE.MeshPhongMaterial).color = new THREE.Color(0xffffff);
                (mesh.material as THREE.MeshPhongMaterial).side = THREE.FrontSide;
            }

            if (render) {
                scene.updateEnvs();
                if (!scene.settings.interactiveProject || scene.loaded) {
                    scene.render();
                }
            }
        }, function() {
            //progress callback
        }, function() {
            //error callback
            if (done) {
                done();
            }
        });
    }

    static joineryMaterialFromMaterialType(materialType: number) : {
        color: number,
        reflectivity: number,
        metalness: number,
        roughness: number,
        coating: string,
        cuisinella: boolean,
        mirror: boolean
    } {
        var options = {
            color: 0xffffff,
            reflectivity: 0,
            metalness: 0.05,
            roughness: 0.9,
            coating: "",
            cuisinella: false,
            mirror: false
        };

        if (materialType >= 1000 && materialType < 3000) {
            // cuisinella colors are sets on channel 1 and 2
            options.cuisinella = true;
        }

        switch (materialType) {
            case 0:
                options.coating = "5eb17fa8d5dbd634e02ce47b"
                break;

            case 1:
                options.coating = "5eb17fa9d5dbd634e02ce47e";
                break;

            case 2:
                options.coating = "5eb17fa9d5dbd634e02ce481";
                break;

            case 3:
                options.coating = "5eb17faad5dbd634e02ce485";
                break;

            case 4:
                options.coating = "5eb17faad5dbd634e02ce487";
                break;

            case 5:
                options.coating = "5eb17fa9d5dbd634e02ce483";
                break;

            case 6:
                options.coating = "5eb17fabd5dbd634e02ce48a"
                break;

            case 7:
                options.coating = "5eb17fabd5dbd634e02ce48d";
                break;

            case 8:
                options.coating = "5f056f4c54deb240c1df7b71";
                break;

            case 9:
                options.coating = "5faa4fa04e35332912579395";
                break;

            case 10:
                options.coating = "6038b39a3f647f589fd20910";
                break;

            case 11:
                options.coating = "604f7300c449b67cda8b4114";
                break;

            case 12:
                options.coating = "606da1bfb1d8db1ab22277c6";
                break;

            case 13:
                options.coating = "615d55a2212ff02793264a00";
                break;

            case 14:
                options.coating = "6297750f932b968d83a488ef";
                break;

            case 15:
                options.coating = "62977510932b968d83a48924";
                break;

            case 16:
                options.coating = "62977511932b968d83a4895d";
                break;

            case 1000:
                options.coating = "5ff5b662e5ec011c6f55f2ca";
                break;
            case 1001:
                options.coating = "5ff5b662e5ec011c6f55f2ce";
                break;
            case 1002:
                options.coating = "5ff5b663e5ec011c6f55f2d2";
                break;
            case 1003:
                options.coating = "5ff5b664e5ec011c6f55f2d6";
                break;
            case 1004:
                options.coating = "5ff5b664e5ec011c6f55f2d8";
                break;
            case 1005:
                options.coating = "5ff5b664e5ec011c6f55f2db";
                break;
            case 1006:
                options.coating = "5ff5b665e5ec011c6f55f2df";
                break;
            case 1007:
                options.coating = "5ff5b666e5ec011c6f55f2e3";
                break;
            case 1008:
                options.coating = "5ff5b666e5ec011c6f55f2e7";
                break;
            case 1009:
                options.coating = "5ff5b667e5ec011c6f55f2eb";
                break
            case 1010:
                options.coating = "5ff5b668e5ec011c6f55f2ef";
                break;
            case 1011:
                options.coating = "5ff5b668e5ec011c6f55f2f2";
                break;
            case 1012:
                options.coating = "5ff5b669e5ec011c6f55f2f6";
                break;
            case 1013:
                options.coating = "5ff5b66ae5ec011c6f55f2fa";
                break;
            case 1014:
                options.coating = '5ff5b66ae5ec011c6f55f2fe';
                break;
            case 1015:
                options.coating = "5ff5b66be5ec011c6f55f301";
                break;
            case 1016:
                options.coating = "5ff5b66be5ec011c6f55f305";
                break;
            case 1017:
                options.coating = "5ff5b66ce5ec011c6f55f308";
                break;
            case 1018:
                options.coating = "5ff5b66ce5ec011c6f55f30c";
                break;
            case 1019:
                options.coating = "5ff5b66de5ec011c6f55f310";
                break;
            case 1020:
                options.coating = "5ff5b66ee5ec011c6f55f314";
                break;
            case 1021:
                options.coating = "5ff5b66fe5ec011c6f55f318";
                break;
            case 1022:
                options.coating = "5ff5b66fe5ec011c6f55f31c";
                break;
            case 1023:
                options.coating = "5ff5b670e5ec011c6f55f31f";
                break;
            case 1024:
                options.coating = "5ff5b670e5ec011c6f55f321";
                break;
            case 1025:
                options.coating = "5ff5b670e5ec011c6f55f324";
                break;
            case 1026:
                options.coating = "5ff5b671e5ec011c6f55f328";
                break;
            case 1027:
                options.coating = "5ff5b671e5ec011c6f55f32b";
                break;
            case 1028:
                options.coating = "5ff5b672e5ec011c6f55f32f";
                break;
            case 1029:
                options.coating = "5ff5b673e5ec011c6f55f333";
                break;
            case 1030:
                options.coating = "5ff5b673e5ec011c6f55f337";
                break;
            case 1031:
                options.coating = "5ff5b674e5ec011c6f55f33b";
                break;
            case 1032:
                options.coating = "5ff5b674e5ec011c6f55f33e";
                break;
            case 1033:
                options.coating = "5ff5b675e5ec011c6f55f342";
                break;
            case 1034:
                options.coating = "5ff5b676e5ec011c6f55f346";
                break;
            case 1035:
                options.coating = "5ff5b677e5ec011c6f55f34a";
                break;
            case 1036:
                options.coating = "5ff5b677e5ec011c6f55f34d";
                break;
            case 1037:
                options.mirror = true;
                break;
            case 2000:
                options.coating = "5ff5b678e5ec011c6f55f353";
                break;
            case 2001:
                options.coating = "5ff5b678e5ec011c6f55f355";
                break;
            case 2002:
                options.coating = "5ff5b678e5ec011c6f55f357";
                break;
            case 2003:
                options.coating = "5ff5b678e5ec011c6f55f359";
                break;
            case 2004:
                options.coating = "5ff5b678e5ec011c6f55f35b";
                break;
            case 2005:
                options.coating = "5ff5b678e5ec011c6f55f35d";
                break;
            case 2006:
                options.coating = "5ff5b678e5ec011c6f55f35f";
                break;
            case 2007:
                options.coating = "5ff5b678e5ec011c6f55f361";
                break;
        }

        return options;
    }

    static radiatorMaterialFromMaterialType(materialType: number) : {
        color: number,
        reflectivity: number,
        metalness: number,
        roughness: number,
        coating: string
    } {
        var options = {
            color: 0xffffff,
            reflectivity: 0,
            metalness: 0.05,
            roughness: 0.9,
            coating: ""
        };

        switch (materialType) {
            case 0:
                options.coating = "5efdeb50265f79731274dda5"
                break;

            case 1:
                options.coating = "5efdec1279de9f72cd5da76d";
                break;

            case 2:
                options.coating = "5efded1a8a8a9672e7155258";
                break;

            case 3:
                options.coating = "5efdec4c3bf5f472eec2e35c";
                break;

            case 4:
                options.coating = "60acb40efed63818211f4906";
                break;

            case 5:
                options.coating = "60acb4be3137d018983161e9";
                break;

            case 6:
                options.coating = "60acb5e29d1f63182050f2c0";
                break;

            case 7:
                options.coating = "60acb678ba752a187443b207";
                break;

            case 8:
                options.coating = "60acb6e5ed5d3a738bdc83d0";
                break;

            case 9:
                options.coating = "61b0822a9c071c0deeea5b65";
                break;

            case 10:
                options.coating = "61b08318b93b7d0e3875024c";
                break;
        }

        return options;
    }

}
